import { DashboardGraphResponse, DashboardGraphValue } from "biohub-model";
import BiohubApi, {
  BiohubResponse,
  extractBiohubErrorResponse,
  newBiohubSuccess,
} from "./axios/BiohubApi";
import { add, addMinutes, sub } from "date-fns";

async function getDashboardSummary(
  initialDate: Date,
  finalDate: Date
): Promise<
  BiohubResponse<{
    flightAmount: number;
    releasedArea: number;
  }>
> {
  try {
    const result = await BiohubApi.get("/dashboard/summary", {
      params: {
        initialDate: initialDate.toISOString(),
        finalDate: finalDate.toISOString(),
      },
    });

    const dashboardSummary = result.data.summary as {
      flightAmount: number;
      releasedArea: number;
    };
    return newBiohubSuccess(dashboardSummary);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

function convertDashboardResponseGraphData(
  response: DashboardGraphResponse,
  initialDate: Date,
  finalDate: Date,
  scaleInMinutes: number
): {
  [classificationName: string]: {
    moment: Date;
    value: number;
  }[];
} {
  const result: {
    [classificationName: string]: {
      moment: Date;
      value: number;
    }[];
  } = {};

  Object.entries(response.data).forEach(([classificationName, entries]) => {
    let actualDate = initialDate;
    let actualIndex = 0;
    let classificationNameArray: {
      moment: Date;
      value: number;
    }[] = [];

    for (const data of entries) {
      for (let i = actualIndex; i < data.index; i++) {
        classificationNameArray.push({
          moment: actualDate,
          value: 0,
        });
        actualDate = addMinutes(actualDate, scaleInMinutes);
      }
      classificationNameArray.push({
        moment: actualDate,
        value: data.value,
      });
      actualIndex = data.index + 1;
      actualDate = addMinutes(actualDate, scaleInMinutes);
    }

    while (actualDate.getTime() < finalDate.getTime()) {
      classificationNameArray.push({
        moment: actualDate,
        value: 0,
      });
      actualDate = addMinutes(actualDate, scaleInMinutes);
    }

    result[classificationName] = classificationNameArray;
  });

  return result;
}

/**
 * Retrieves area and drone data over a specified time range for a given direct client.
 * @param directClientId - The unique identifier for the direct client.
 * @param initialDate - The start date of the time range.
 * @param finalDate - The end date of the time range.
 * @returns A Promise that resolves to a BiohubResponse containing the area and drone data.
 *          If successful, the response will contain the data in the "data" property.
 *          If there is an error, the response will contain the error information.
 */
async function readDataAreaAndDronePerTime(
  directClientId: string,
  initialDate: Date,
  finalDate: Date,
  scaleInMinutes: number
): Promise<BiohubResponse<DashboardGraphResponse>> {
  try {
    const result = await BiohubApi.get("/dashboard/area-and-drone-per-time", {
      params: {
        directClientId: directClientId,
        initialDate: initialDate.toISOString(),
        finalDate: finalDate.toISOString(),
        scale: scaleInMinutes,
      },
    });

    const responseGraph = result.data["dashboardInfos"] as DashboardGraphResponse;
    return newBiohubSuccess(responseGraph);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

/**
 * Retrieves area and input data over a specified time range for a given direct client.
 * @param directClientId - The unique identifier for the direct client.
 * @param initialDate - The start date of the time range.
 * @param finalDate - The end date of the time range.
 * @returns A Promise that resolves to a BiohubResponse containing the area and input data.
 *          If successful, the response will contain the data in the "data" property.
 *          If there is an error, the response will contain the error information.
 */
async function readDataAreaAndInputPerTime(
  directClientId: string,
  initialDate: Date,
  finalDate: Date,
  scaleInMinutes: number
): Promise<BiohubResponse<DashboardGraphResponse>> {
  try {
    const result = await BiohubApi.get("/dashboard/area-and-input-per-time", {
      params: {
        directClientId: directClientId,
        initialDate: initialDate.toISOString(),
        finalDate: finalDate.toISOString(),
        scale: scaleInMinutes,
      },
    });

    const responseGraph = result.data["dashboardInfos"] as DashboardGraphResponse;
    return newBiohubSuccess(responseGraph);
  } catch (e) {
    return extractBiohubErrorResponse(e);
  }
}

export default {
  convertDashboardResponseGraphData,
  getDashboardSummary,
  readDataAreaAndDronePerTime,
  readDataAreaAndInputPerTime,
};
