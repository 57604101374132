import { addMinutes, format } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import _ from "lodash";

export default (props: {
  data: {
    [classificationName: string]: {
      moment: Date;
      value: number;
    }[];
  };
  initialDate: Date;
  finalDate: Date;
  scaleInMinutes: number;
}): JSX.Element => {
  const intl = useIntl();

  const data = props.data;

  const chartsData = getChartsData(data, props.initialDate, props.finalDate, props.scaleInMinutes);

  const classificationNames = chartsData.classificationNames.filter((name) => name !== "total");

  const [visibleCurves, setVisibleCurves] = useState<{
    [classificationName: string]: boolean;
  }>({});
  useEffect(() => {
    const oldVisibleCurvesNames = Object.keys(visibleCurves);
    if (!_.isEqual(oldVisibleCurvesNames, classificationNames)) {
      setVisibleCurves(
        Object.fromEntries(
          classificationNames.map((classificationName) => [classificationName, true])
        )
      );
      setTotalLineVisible(true);
    }
  }, [classificationNames]);

  const [totalLineVisible, setTotalLineVisible] = useState(true);

  const circularColors = ["red", "orange", "blue", "#FFBF00" /* amber */, "#3498db", "#8e44ad"];

  const yAxisDomain = [0, Math.ceil(chartsData.maxValue) + 1];

  const charts = chartsData.charts;

  const totalLineColor = "green";
  const notVisibleColor = "gray";

  const totalTerm = intl.formatMessage({ id: "info.total" });

  return (
    <div>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={charts}>
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey="moment"
            tickFormatter={(value: any) => {
              if (typeof value === "number") {
                const actualDate = new Date(value);
                return format(actualDate, "dd/MM/yyyy");
              }

              return "";
            }}
            interval={Math.floor(charts.length / 5)}
            tickLine={true}
            fontSize={14.5}
          />

          <YAxis
            domain={yAxisDomain}
            tickFormatter={(yAxisDomain) => {
              if (yAxisDomain === 0) {
                return "";
              } else {
                return `${Math.round(yAxisDomain).toFixed(0)} ha`;
              }
            }}
          />

          <Tooltip
            labelFormatter={(moment: any) => {
              if (typeof moment === "number") {
                return format(new Date(moment), "dd/MM/yyyy");
              }
              return "";
            }}
            formatter={(value: number) => {
              return `${Number(value).toFixed(2)} ha`;
            }}
          />

          <Legend
            verticalAlign="top"
            align="center"
            onClick={(entry) => {
              const name = entry.value;

              if (name === "total") {
                setTotalLineVisible(!totalLineVisible);
              } else {
                setVisibleCurves({
                  ...visibleCurves,
                  [name]: !visibleCurves[name],
                });
              }
            }}
            payload={[
              {
                value: "total",
                id: "total",
                color: totalLineVisible ? totalLineColor : notVisibleColor,
              },
              ...classificationNames.map((classificationName, index) => ({
                value: classificationName,
                id: classificationName,
                color: visibleCurves[classificationName]
                  ? circularColors[index % circularColors.length]
                  : notVisibleColor,
              })),
            ]}
            formatter={(value) => {
              if (typeof value === "string" && value === "total") {
                return totalTerm;
              }

              return value;
            }}
          />

          {classificationNames.map((classificationName, index) => {
            const isVisible = visibleCurves[classificationName];

            if (!isVisible) return <></>;

            return (
              <Line
                key={classificationName}
                dataKey={classificationName}
                name={classificationName}
                stroke={circularColors[index % circularColors.length]}
                dot={false}
                strokeWidth={2.5}
                type="monotone"
                animationDuration={2.5}
              />
            );
          })}

          {totalLineVisible && (
            <Line
              key={"total"}
              dataKey={"total"}
              name={"total"}
              stroke={totalLineColor}
              dot={false}
              strokeWidth={2.5}
              type="monotone"
              animationDuration={2.5}
            />
          )}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

function getChartsData(
  data: {
    [classificationName: string]: {
      moment: Date;
      value: number;
    }[];
  },
  initialDate: Date,
  finalDate: Date,
  scaleInMinutes: number
): {
  charts: ({
    moment: number;
  } & {
    [classificationName: string]: number;
  })[];
  classificationNames: string[];
  maxValue: number;
} {
  const classificationNames: string[] = [];
  const momentAndValuesMap: {
    [momentText: string]: {
      [classificationName: string]: number;
    };
  } = {};

  let maxValue = 0;

  for (const [classificationName, entries] of Object.entries(data)) {
    if (!classificationNames.includes(classificationName)) {
      classificationNames.push(classificationName);
    }

    for (const entry of entries) {
      const momentText = `${entry.moment.getTime()}`;
      if (momentAndValuesMap[momentText] === undefined) {
        momentAndValuesMap[momentText] = {};
      }

      const value = entry.value;

      if (value > maxValue) {
        maxValue = value;
      }

      momentAndValuesMap[momentText][classificationName] = value;
    }
  }

  classificationNames.push("total");

  const charts: ({
    moment: number;
  } & {
    [classificationName: string]: number;
  })[] = [];

  const momentAndValuesMapEntries = Object.entries(momentAndValuesMap);
  if (momentAndValuesMapEntries.length > 0) {
    for (const [momentText, momentClassificationsMap] of momentAndValuesMapEntries) {
      const totalizator = Object.entries(momentClassificationsMap)
        .map(([_, value]) => value)
        .reduce((a, b) => a + b, 0);

      charts.push({
        moment: parseInt(momentText),
        ...momentClassificationsMap,
        total: totalizator,
      });
    }
  } else {
    let iterDate = new Date(initialDate);

    while (iterDate.getTime() < finalDate.getTime()) {
      charts.push({
        moment: iterDate.getTime(),
        total: 0,
      });

      iterDate = addMinutes(iterDate, scaleInMinutes);
    }
  }

  return {
    charts: charts,
    classificationNames: classificationNames,
    maxValue: maxValue,
  };
}
