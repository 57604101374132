import { SystemThunk } from "../systemThunk";
import { BiohubError } from "../../services/axios/BiohubApi";
import DashboardService from "../../services/DashboardService";
import { DashboardGraphResponse } from "biohub-model";

// TODO: Put that types in the BiohubModel

type DashboardGraphValue = {
  index: number;
  value: number;
};

export const DASHBOARD_LOAD_HECTARES = "DASHBOARD_LOAD_HECTARES";
export const DASHBOARD_LOAD_HECTARES_ERROR = "DASHBOARD_LOAD_HECTARES_ERROR";
export const DASHBOARD_LOAD_HECTARES_SUCCESS = "DASHBOARD_LOAD_HECTARES_SUCCESS";

export const DASHBOARD_LOAD_INCOME = "DASHBOARD_LOAD_INCOME";
export const DASHBOARD_LOAD_INCOME_ERROR = "DASHBOARD_LOAD_INCOME_ERROR";
export const DASHBOARD_LOAD_INCOME_SUCCESS = "DASHBOARD_LOAD_INCOME_SUCCESS";

export type DashboardActions =
  | {
      type: typeof DASHBOARD_LOAD_HECTARES;
    }
  | {
      type: typeof DASHBOARD_LOAD_HECTARES_ERROR;
      payload: {
        error: BiohubError;
      };
    }
  | {
      type: typeof DASHBOARD_LOAD_HECTARES_SUCCESS;
      payload: {
        data: DashboardGraphResponse;
        initialDate: Date;
        finalDate: Date;
        scale?: number;
      };
    }
  | {
      type: typeof DASHBOARD_LOAD_INCOME;
    }
  | {
      type: typeof DASHBOARD_LOAD_INCOME_ERROR;
      payload: {
        error: BiohubError;
      };
    }
  | {
      type: typeof DASHBOARD_LOAD_INCOME_SUCCESS;
      payload: {
        data: DashboardGraphResponse;
        initialDate: Date;
        finalDate: Date;
        scale?: number;
      };
    };
