import React, { FC, useEffect, useMemo, useState } from "react";
import imageClients from "../../../assets/icon/icon_clients.svg";
import imageCoverageArea from "../../../assets/icon/icon_coverage_area.svg";
import imageFlights from "../../../assets/icon/icon_released.svg";
import imageDrone from "../../../assets/icon/icon_report_drone.svg";
import {
  Grid,
  Icon,
  Card as MaterialCard,
  CardContent,
  Box,
  CircularProgress,
  makeStyles,
  SvgIcon,
  FormControl,
  InputLabel,
  Paper,
} from "@material-ui/core";
import { Profile } from "biohub-model";
import { IntlShape, useIntl } from "react-intl";
import DashboardService from "../../../services/DashboardService";
import { Loading } from "../styles";
import DatePicker from "react-date-picker";
import { FiltersGraph } from "../styles";
import { useSelector } from "react-redux";
import { SystemState } from "../../../store/reducers/systemReducer";
import { format, isValid } from "date-fns";
import Graph from "./graph";

const useStyles = makeStyles({
  root: {
    marginBottom: 10,
    marginRight: 10,
  },
});

function formatNumber(number: number, intl: IntlShape): string {
  return intl.formatNumber(number, {
    maximumFractionDigits: 1,
    notation: "compact",
    compactDisplay: "short",
  });
}

function loadedDashBoardCardsRight(
  droneAmount: number,
  clientAmount: number,
  intl: IntlShape
): Array<DashboardCardProps> {
  const aTot: Array<DashboardCardProps> = [
    {
      svgPath: imageDrone,
      material: false,
      value: formatNumber(droneAmount, intl),
      legend: intl.formatMessage({ id: "dashboard.total.drones" }),
    },
    {
      svgPath: imageClients,
      material: false,
      value: formatNumber(clientAmount, intl),
      legend: intl.formatMessage({ id: "dashboard.total.clients" }),
    },
  ];

  return aTot;
}

function loadedDashBoardCardsLeft(
  releasedAmount: number,
  releasedArea: number,
  intl: IntlShape
): Array<DashboardCardProps> {
  const dashboardCards: Array<DashboardCardProps> = [
    {
      title:
        releasedAmount === 1
          ? intl.formatMessage({ id: "dashboard.total.operation" })
          : intl.formatMessage({ id: "dashboard.total.operations" }),
      svgPath: imageFlights,
      material: false,
      value: `${formatNumber(releasedAmount, intl)}`,
      legend:
        releasedAmount === 1
          ? intl.formatMessage({ id: "dashboard.total.releasedFlight" })
          : intl.formatMessage({ id: "dashboard.total.releasedFlights" }),
      // legend: intl.formatMessage({ id: "dashboard.total.releasedFlights" }),
    },
    {
      title:
        releasedArea === 1
          ? intl.formatMessage({ id: "dashboard.total.monthFlightYield" })
          : intl.formatMessage({ id: "dashboard.total.monthFlightYield" }),
      svgPath: imageCoverageArea,
      material: false,
      value: `${formatNumber(releasedArea, intl)}`,
      legend: `ha`,
    },
  ];
  return dashboardCards;
}

type DashboardCardProps = {
  title?: string;
  value: string;
  legend?: string;
  unit?: string;
  legendSufix?: string;
} & ({ material: true; icon: string } | { material: false; svgPath: string });

function DashboardCardsLeft(props: DashboardCardProps) {
  const classes = useStyles();
  return (
    <MaterialCard className={classes.root} style={{ maxWidth: "20em", minWidth: "5em" }}>
      <CardContent>
        <Box height={"4em"}>
          <Grid container xs={12}>
            <Grid item xs={4}>
              {props.material ? (
                <Icon color={"primary"} fontSize={"large"}>
                  {props.icon}
                </Icon>
              ) : (
                <SvgIcon component="object" color={"primary"} fontSize={"large"}>
                  <embed type="image/svg+xml" src={props.svgPath} style={{ height: "100%" }} />
                </SvgIcon>
              )}
            </Grid>
            <Grid item xs={8} alignContent="flex-end">
              <div
                style={{
                  fontSize: 20,
                  marginLeft: "1em",
                }}
              >
                {props.value}
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box height={20}>
              <>
                <div style={{ fontSize: 12 }}>{props.legend}</div>
                {props.legendSufix !== undefined && (
                  <div style={{ fontSize: 10.5 }}>{props.legendSufix}</div>
                )}
              </>
            </Box>
          </Grid>
        </Box>
      </CardContent>
    </MaterialCard>
  );
}

function DashboardCardsRight(props: DashboardCardProps) {
  const classes = useStyles();
  return (
    <MaterialCard className={classes.root} style={{ minWidth: "6em" }}>
      <CardContent>
        <Box height={100}>
          <Grid container xs={12}>
            <h4 style={{ marginBottom: "0px", marginTop: "0px" }}>{props.title}</h4>
            <Grid item xs={4}></Grid>
            <Grid item alignContent="flex-end">
              <div
                style={{
                  fontSize: 15,
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {props.material ? (
                    <Icon color={"primary"} style={{ fontSize: "4em" }}>
                      {props.icon}
                    </Icon>
                  ) : (
                    <SvgIcon component="object" color={"primary"} style={{ fontSize: "4em" }}>
                      <embed
                        type="image/svg+xml"
                        src={props.svgPath}
                        style={{ height: "100%", paddingBottom: 5 }}
                      />
                    </SvgIcon>
                  )}
                  {props.legendSufix !== undefined && (
                    <div style={{ fontSize: 10.5 }}>{props.legendSufix}</div>
                  )}
                  <div style={{ fontSize: "2.5em", marginTop: "0.7em", marginLeft: "0.2em" }}>
                    {props.value.toString().replace(",", ".")}{" "}
                  </div>
                  <div
                    style={{
                      marginLeft: 2,
                      marginTop: "auto",
                      marginBottom: "0.8em",
                      fontWeight: "bold",
                      fontSize: 14,
                    }}
                  >
                    {`${props.legend}`}
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </MaterialCard>
  );
}

type DashboardSummary = {
  flightAmount: number;
  releasedArea: number;
};

type Entry = {
  moment: Date;
  value: number;
};

type DashboardData = {
  [classificationName: string]: Entry[];
};

const DashboardGraphComponent = () => {
  const today = new Date();
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 15);

  const [startDate, setStartDate] = useState(thirtyDaysAgo);
  useState(today);
  const [endDate, setEndDate] = useState(today);

  const intl = useIntl();

  const [totSummary, setTotSummary] = useState<DashboardSummary | undefined | null>(undefined);

  const [dashboardSummary, setDashboardSummary] = useState<DashboardSummary | undefined | null>(
    undefined
  );

  const [scale, setScale] = useState(60 * 24); ///daily

  const [areaInputData, setAreaInputData] = useState<DashboardData | null>(null);
  const [areaDroneData, setAreaDroneData] = useState<DashboardData | null>(null);

  const systemProfile: Profile | null = useSelector(
    (state: SystemState) => state.profile.userProfile
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const initialDate = startDate;
        const finalDate = endDate;

        const totSummary = await DashboardService.getDashboardSummary(initialDate, finalDate);

        if (!totSummary.success) {
          setDashboardSummary(null);
          setTotSummary(null);
        } else {
          setTotSummary(totSummary.data);
          setDashboardSummary(totSummary.data);
        }

        const dataAreaInput = await DashboardService.readDataAreaAndInputPerTime(
          systemProfile?.directClientId as string,
          initialDate,
          finalDate,
          scale
        );

        if (dataAreaInput.success) {
          setAreaInputData(
            DashboardService.convertDashboardResponseGraphData(
              dataAreaInput.data,
              initialDate,
              finalDate,
              scale
            )
          );
        } else {
          setAreaInputData(null);
        }

        const dataAreaDrone = await DashboardService.readDataAreaAndDronePerTime(
          systemProfile?.directClientId as string,
          initialDate,
          finalDate,
          scale
        );

        if (dataAreaDrone.success) {
          setAreaDroneData(
            DashboardService.convertDashboardResponseGraphData(
              dataAreaDrone.data,
              initialDate,
              finalDate,
              scale
            )
          );
        } else {
          setAreaDroneData(null);
        }
      } catch (error) {
        console.error("Erro ao obter dados:", error);
      }
    };

    fetchData();
  }, [startDate, endDate, systemProfile]);

  const dashboardCards =
    dashboardSummary !== undefined && dashboardSummary !== null
      ? loadedDashBoardCardsLeft(dashboardSummary.flightAmount, dashboardSummary.releasedArea, intl)
      : [];

  const droneAmount = useSelector((state: SystemState) => state.collections.drones.length);
  const clientAmount = useSelector(
    (state: SystemState) => state.profile.indirectClients?.length ?? 0
  );

  const totCards =
    totSummary !== undefined && totSummary !== null
      ? loadedDashBoardCardsRight(droneAmount, clientAmount, intl)
      : [];

  const hrStyle = {
    backgroundColor: "rgba(29, 79, 144, 0.3)",
    height: "2.5px",
    border: "none",
    width: "100%",
  };

  const handleStartDateChange = (date: any) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: any) => {
    setEndDate(date);
  };

  const graphContainerStyle = {
    backgroundColor: "#FFF",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",
    width: "100%",
    marginBottom: "20px",
  };

  const graphTitleStyle = {
    fontSize: "20px",
    marginBottom: "15px",
    color: "#333",
  };

  const columnRightStyle = {
    backgroundColor: "#FFF",
    borderRadius: "3px",
    padding: "20px",
  };

  return (
    <div>
      <div style={{ width: "100%", marginTop: 0 }}>
        <FiltersGraph>
          <Grid
            container
            spacing={2}
            style={{ padding: 0, backgroundColor: "#fff", marginBottom: "1em" }}
          >
            {/* Coluna à esquerda */}
            <Grid item xs={12} md={4}>
              <div style={{ padding: 5, backgroundColor: "#fff" }}>
                <h2 style={{ fontSize: "20px", marginBottom: "15px", color: "#333" }}>
                  {/* Totalizadores */}
                  {intl.formatMessage({ id: "dashboard.total" })}
                </h2>
                {totCards.length !== 0 ? (
                  <Grid container xs={12}>
                    {totCards.map((cardInfo, index) => {
                      const isLastCard = index === totCards.length - 1;
                      return (
                        <Grid item xs={6} sm={6} md={6} style={{ marginRight: isLastCard ? 0 : 0 }}>
                          <div style={{ height: "10em" }}>
                            {" "}
                            <DashboardCardsLeft {...cardInfo} />
                          </div>
                        </Grid>
                      );
                    })}
                  </Grid>
                ) : (
                  <Loading>
                    <CircularProgress />
                  </Loading>
                )}
              </div>
            </Grid>

            {/* Coluna à direita */}
            <Grid item xs={12} md={8}>
              <Paper style={columnRightStyle}>
                <Grid container style={{ marginBottom: 20, marginTop: 10 }}>
                  <Grid item xs={12} sm={6} md={3} style={{ marginRight: "1em", marginTop: "5px" }}>
                    <InputLabel>{intl.formatMessage({ id: "dashboard.start.date" })}</InputLabel>
                    <FormControl variant="outlined" style={{ marginTop: 11 }}>
                      <DatePicker
                        name="initialDate"
                        value={startDate}
                        onChange={handleStartDateChange}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={3} style={{ marginRight: "1em", marginTop: "5px" }}>
                    <InputLabel>{intl.formatMessage({ id: "dashboard.end.date" })}</InputLabel>
                    <FormControl variant="outlined" style={{ marginTop: 11 }}>
                      <DatePicker
                        name="initialDate"
                        value={endDate}
                        onChange={handleEndDateChange}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                {dashboardCards.length !== 0 ? (
                  <Grid container>
                    {dashboardCards.map((cardInfo, index) => (
                      <Grid item xs={12} sm={6} md={6} key={index}>
                        <DashboardCardsRight {...cardInfo} />
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Loading>
                    <CircularProgress />
                  </Loading>
                )}
              </Paper>
            </Grid>
          </Grid>
        </FiltersGraph>
      </div>

      <hr style={hrStyle} />

      <Grid
        container
        spacing={2}
        style={{ padding: 0, backgroundColor: "transparent", marginBottom: "1em" }}
      >
        <Grid item xs={12} md={6}>
          <div style={graphContainerStyle}>
            <h2 style={graphTitleStyle}>
              {intl.formatMessage({ id: "dashboard.area.drone.time" })}
            </h2>
            {areaDroneData ? (
              <Graph
                data={areaDroneData}
                initialDate={startDate}
                finalDate={endDate}
                scaleInMinutes={scale}
              />
            ) : (
              <Loading>
                <CircularProgress />
              </Loading>
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div style={{ ...graphContainerStyle }}>
            <h2 style={graphTitleStyle}>
              {intl.formatMessage({ id: "dashboard.area.input.time" })}
            </h2>
            {areaInputData ? (
              <Graph
                data={areaInputData}
                initialDate={startDate}
                finalDate={endDate}
                scaleInMinutes={scale}
              />
            ) : (
              <Loading>
                <CircularProgress />
              </Loading>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default DashboardGraphComponent;
